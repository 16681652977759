
document.querySelector('.header__burger').onclick = () => {
    document.querySelector('.header__wrapper').classList.toggle('show');
    document.querySelector('.main').classList.toggle('blur');
    document.querySelector('.header__burger').classList.toggle('close');
}

let tabNav = document.querySelectorAll('.item__btn');
let tabContent = document.querySelectorAll('.destination__item');

tabNav.forEach((elem) => {
    elem.addEventListener("click", function (e) {
        e.preventDefault();
        tabNav.forEach((elem)=>{
            elem.classList.remove('active');
        });
        this.classList.add('active');
        let activeTabAttr = e.target.getAttribute("data-tab");
        tabContent.forEach((elem) => {
            elem.classList.remove('active');
        })
        tabContent.forEach((elem) => {
            if(elem.getAttribute("data-content") == activeTabAttr){
                elem.classList.add('active');
            }

        })

    });
});


let popUp = document.querySelector('.overflow');
let btnPop = document.querySelectorAll('.pop');


btnPop.forEach((elem) => {
    elem.addEventListener("click", function (e) {
        e.preventDefault();
        let info = e.target.getAttribute("data-pop");
        popUp.classList.add('active');
        popUp.querySelector('.form__info').value = info;
    })
});
document.querySelector('.form__close').onclick = (e) => {
    e.preventDefault();
    popUp.classList.remove('active');
}
if(document.body.clientWidth > 767){
    document.querySelectorAll('.more').forEach((elem) => {
        elem.addEventListener("click", function (e) {
            e.preventDefault();
            document.querySelectorAll('.price__item').forEach((article) => {
                article.classList.toggle('active');
            });

            if(elem.querySelector('.btn-text').textContent == 'подробнее') {
                elem.querySelector('.btn-text').textContent = 'свернуть';
            } else {
                elem.querySelector('.btn-text').textContent = 'подробнее';
            }
            document.querySelector('.price-btn').classList.toggle('show');
            document.querySelector('.price__text').classList.toggle('show');

        })
    });
} else {
    document.querySelectorAll('.more').forEach((elem) => {
        elem.addEventListener("click", function (e) {
            e.preventDefault();
            if(elem.querySelector('.btn-text').textContent == 'подробнее') {
                document.querySelectorAll('.price__item').forEach((article) => {
                    article.classList.remove('active');
                    article.querySelector('.btn-text').textContent = 'подробнее';
                    article.closest('article').nextElementSibling.classList.remove('show');
                })
                this.closest('article').classList.toggle('active');
                elem.querySelector('.btn-text').textContent = 'свернуть';
                this.closest('article').nextElementSibling.classList.toggle('show');
            } else {
                document.querySelectorAll('.price__item').forEach((article) => {
                    article.classList.remove('active');
                    article.querySelector('.btn-text').textContent = 'подробнее';
                    article.closest('article').nextElementSibling.classList.remove('show');
                })
                elem.querySelector('.btn-text').textContent = 'подробнее';
            }

            // this.closest('article').querySelector('.price__text').classList.toggle('show');
            // this.closest('article').closest('.price').querySelector('.price-btn').classList.toggle('show');
            // document.querySelector('.price__text').classList.toggle('show');

        })
    });
}




 let moreAll = document.querySelector('.more__all');
// moreAll.onclick = () => {
//     document.querySelectorAll('.price__item').forEach((elem) => {
//         elem.classList.add('active');
//     })
//     moreAll.classList.toggle('active');
//     document.querySelector('.price .pop');
// }
// moreAll.classList.add('pop');
// moreAll.onclick = () => {
//     popUp.classList.add('active');
//     popUp.querySelector('.form__info').value = moreAll.getAttribute("data-pop");
// }



// const swiper = new Swiper('.swiper', {
//     // Optional parameters
//     direction: 'vertical',
//     loop: true,
//     // And if we need scrollbar
//     scrollbar: {
//         el: '.swiper-scrollbar',
//     },
// });

// document.querySelectorAll('.destination__menu')


var swiper = new Swiper(".mySwiper", {
    slidesPerView: 4,
    spaceBetween: 30,
    loop: true,
    grabCursor: true,
    centeredSlides: true,
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    },
    slidesPerView: "auto",
    pagination: {
        el: ".swiper-pagination",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 10,
            releaseOnEdges: true,
            centeredSlides: true,
        },
        767: {
            spaceBetween: 10,
            slidesPerView: 3
        },
        1440: {
            slidesPerView: 4
        }
    }
});



$('input[type=tel]').inputmask("+7(999)999-99-99");



// let scrollPrev = 0;
//
// function scrollReveal() {
//     var revealPoint = 150;
//     var revealElement = document.querySelectorAll(".scrollReveal");
//     for (var i = 0; i < revealElement.length; i++) {
//         var windowHeight = window.innerHeight;
//         var revealTop = revealElement[i].getBoundingClientRect().top;
//         if (revealTop < windowHeight - revealPoint) {
//             revealElement[i].classList.add("animated");
//         } else {
//             revealElement[i].classList.remove("animated");
//         }
//     }
// }
//
// window.addEventListener("scroll", scrollReveal);
// scrollReveal();